import { useState } from "react";

const UniversityFinder = () => {
  const [state, setState] = useState(true);
  const [checkedWA, setCheckedWA] = useState(false);
  const [checkedNT, setCheckedNT] = useState(false);
  const [checkedQLD, setCheckedQLD] = useState(false);
  const [checkedSA, setCheckedSA] = useState(false);
  const [checkedNSW, setCheckedNSW] = useState(false);
  const [checkedVIC, setCheckedVIC] = useState(false);
  const [checkedTAS, setCheckedTAS] = useState(false);
  const [checkedACT, setCheckedACT] = useState(false);

  return (
    <main className="max-w-[1080px] min-h-[100vh] mx-auto my-4">
      <h1 className="text-2xl font-semibold text-center my-4">
        Explore Australia before you start your journey
      </h1>
      <div className="relative h-[50vh] sm:ml-[10rem] md:ml-[20rem] ml-10">
        {/* Western Australia */}
        {!checkedWA && (
          <div className="absolute top-[8rem] left-[1.5rem] z-10 text-white transition-all ease-in-out duration-300">
            <p className="text-xs">Western Australia</p>
          </div>
        )}
        {!checkedNT && (
          <div className="absolute top-[5rem] left-[8.4rem] z-10 text-white transition-all ease-in-out duration-300">
            <p className="text-[8px]">Northern Territory</p>
          </div>
        )}
        {!checkedQLD && (
          <div className="absolute top-[6.3rem] left-[14rem] z-10 text-white transition-all ease-in-out duration-300">
            <p className="text-[10px]">Queensland</p>
          </div>
        )}
        {!checkedSA && (
          <div className="absolute top-[9.5rem] left-[8.4rem] z-10 text-white transition-all ease-in-out duration-300">
            <p className="text-[10px]">South Australia</p>
          </div>
        )}
        {!checkedNSW && (
          <div className="absolute top-[11rem] left-[15rem] z-10 text-white transition-all ease-in-out duration-300">
            <p className="text-[10px]">New South Wales</p>
          </div>
        )}
        {!checkedVIC && (
          <div className="absolute top-[15.5rem] left-[15rem] z-10 text-white transition-all ease-in-out duration-300">
            <p className="text-[10px]">Victoria</p>
          </div>
        )}
        {!checkedTAS && (
          <div className="absolute top-[19rem] left-[15.2rem] z-10 text-white transition-all ease-in-out duration-300">
            <p className="text-[7px]">Tasmania</p>
          </div>
        )}
        {!checkedACT && (
          <div className="absolute top-[14.5rem] left-[20rem] z-10 text-white transition-all ease-in-out duration-300">
            <p className="text-[5px]">Canberra</p>
          </div>
        )}
        <svg
          version="1.1"
          id="Layer_1"
          viewBox="0 0 431.5 654"
          style={{ enableBackground: "new 0 0 431.5 654" }}
          xmlSpace="preserve"
          className={`${
            checkedWA ? "fill-blue-600" : "fill-[#d3d3d3]"
          } w-[8rem] absolute top-[2.5rem] cursor-pointer transition-all ease-in-out duration-300`}
          onClick={() => {
            setCheckedWA(true);
            setCheckedNT(false);
            setCheckedQLD(false);
            setCheckedSA(false);
            setCheckedNSW(false);
            setCheckedVIC(false);
            setCheckedTAS(false);
            setCheckedACT(false);
            setState(false);
          }}
        >
          <path
            d="M118.6,655c-1.4,0-2.8,0-4.3-0.3c-3.5-3.9-7.7-2.4-11.7-2.3c-4.8,0.1-9.7-0.4-14.5-0.7c-0.1,0-0.4-0.1-0.4-0.2
	c-0.7-4.9-4.7-4.2-7.8-4.3c-7.7-0.1-11-5.6-14.4-11.2c-3-5-7.2-8.1-13.3-8c-4.1,0-7.1-2.1-7.6-6.1c-0.7-5.7-1.1-11.5-0.9-17.3
	c0.1-2.7,2.4-4.2,5.5-2.6c4.1,2.2,6.3,1.3,8.3-3.1c0.4-0.9,1-1.9,1.7-2.3c4.7-2.6,3.4-6.6,2.8-10.6c-0.6-3.9-1.7-7.8-1.3-11.6
	c0.5-5.3,2.2-10.4,3.4-15.6c0.6-2.5,1.2-5,1.6-6.7c-0.8-2-1.6-3.5-2-5.1c-1.3-4.8-2-9.8-3.9-14.4c-4-9.3-9.5-18.1-12.9-27.7
	c-2.6-7.2-4.6-15.3-3.9-22.7c1-11.1,0.4-21-7-29.7c-1.1-1.3-1.6-3.2-2.4-4.8c-2.1-4.2-3.8-8.7-6.5-12.6c-3.6-5.4-8.6-9.9-7.5-17.4
	c0.4-2.6,0.8-5.6-0.2-7.9c-2.3-5.4-5.3-10.6-8.2-15.8c-1.3-2.4-3.1-4.5-4.5-6.8c-1.5-2.4-1.7-4.3,1.6-5.5c1.6-0.5,3.9-1.8,4.1-2.9
	c0.5-3.7,2.8-3.5,5.5-4.2c1.8-0.5,3.9-2.6,4.5-4.4c1.1-3.3-1.9-7.2,1.2-10.6c0.2-0.3-0.9-1.9-1.5-2.8c-1.4-1.8-3.1-3.5-4.3-5.4
	c-3.6-6-6.9-12-10.3-18.1c-0.5-0.9-0.5-2.1-0.8-3.2c-1.1-3.7-1.6-7.7-3.6-10.9c-4-6.7-4.3-16.5,0.4-22.7c4.2-5.5,6.6-11.2,6.5-18.5
	c-0.1-5.5,0.9-10.8-2.2-15.9c-0.7-1.1-0.6-3.1-0.1-4.4c2.7-6.4,5.5-12.9,8.7-19.1c0.7-1.4,3.2-1.9,4.8-2.8c0.4,2,1.3,4,1.2,6
	c-0.2,4.2-0.8,8.4-1.3,13.5c3-0.7,5.9-0.5,6.7-1.7c2.4-3.5,4.7-7.4,5.5-11.5c0.8-4.2,2.4-7.1,6.1-8.7c4.1-1.8,8.4-3.1,12.5-4.6
	c2.5-0.9,5.5-1.4,7.4-3c5.3-4.4,10-9.6,15.4-14c3.3-2.6,7.6-3.9,11.2-6.3c5.8-3.9,12.6-1.9,18.7-3.7c0.6-0.2,1.5,0.4,2.2,0.8
	c3.4,1.9,12.3,1,15-1.8c5.1-5.1,11-8,18.5-8.2c6.3-0.2,12.6-1.4,15.4-8.5c0.3-0.7,1.6-1.3,2.4-1.3c3.9,0.1,7.8,0.5,11.8,0.6
	c1.4,0,2.8-0.3,4.1-0.8c1.5-0.5,3-2,4.4-1.8c7.8,1.1,14.2-2.7,21.1-5.1c14.1-4.8,23.2-14.3,28.3-28.3c1.5-4.2,3.4-8.9,8.9-11
	c4.2-1.7,7.5-5.4,10.8-7.9c-2-2.5-4.1-4.1-4.8-6.3c-0.9-2.7-1.1-5.8-0.9-8.7c0.1-3.3-0.1-7.4,1.7-9.8c2.5-3.5,3-9.4,9.1-9.6
	c0.3,0,0.9-1,0.9-1.6c-0.1-4.7,3.4-3.4,5.6-3.5c0.5-2.1,0.6-4.1,1.5-5.8c0.7-1.4,2.3-2.3,3.6-3.4c0.8,1.3,1.6,2.7,2.5,3.9
	c1,1.4,2.2,2.6,3.4,3.9c0.3,0.4,0.9,0.8,1,1.2c0.6,5.4,4.1,9.6,5.9,14.5c0.7,1.8,1.7,3.5,2.4,4.9c2.9-3.7,5.4-6.9,7.9-10.1
	c0.4,0.3,0.8,0.6,1.2,0.9c0.2-1.6,0.4-3.2,0.5-4.1c-2.2-2.9-5.7-5.5-5.9-8.4c-0.4-3.5,2-7.2,3.2-11.1c4,0.3,8.9-0.7,11.5,1.3
	c3.9,3.1,6.9,3.3,10.6,1.4c-0.2-1.5-1-3.2-0.5-4c2.1-3.5,1.7-6.1-1.6-8.5c-2.7-2-3-4.4-1.8-7.7c1.4-3.9,4.7-4.4,7.5-6.2
	c6.2-4,6.3-4.2,11.3,1c-0.2-1-0.6-1.8-0.4-2.4c0.2-0.6,1.4-1.1,1.4-1.5c-0.6-4.9,0.1-5.7,4.6-6.3c1.4-0.2,2.6-1.3,4.8-2.6
	c-6.1-1.6-6.1-1.6-4.6-3.5c-1.6-1.5-3.3-3-4.9-4.5c0.1-0.7,0.3-1.3,0.4-2c8-1.9,16-3.9,24.1-5.9c0.3-7.5,0.3-7.5,8.1-8.5
	c0.6-0.1,1.3-0.3,1.9-0.4c2.6-0.4,5.3-0.8,8.7-1.3c0.3-0.6,0.7-2.9,2.1-3.8c6-3.8,12.8-3.7,18.6-0.2c4.7,2.9,8.8,7.4,12.2,11.9
	c1.8,2.3,2.9,4.6,5.9,5.7c3.8,1.4,4.2,4.6,3,8.1c-0.7,2-2.1,3.9-2.2,5.8c-0.1,1.5,1.2,3.1,1.7,4.2c3.2-2.9,5.6-5.1,6.9-6.3
	c0.8-3.1,0.7-6,1.9-6.7c1.5-0.9,4.1-0.2,6.1,0.4c2.1,0.6,4.1,1.8,6,3.1c-0.3,2.5-0.5,4.6-0.8,6.7c-0.4,3.3-0.6,6.6,2.8,9.2
	c-0.2,110.3-0.6,220.2-0.8,330.1c-0.1,50.8-0.1,101.6,0,152.5c0,4.9-1.8,7.6-6.2,10c-9.9,5.4-20.6,8.3-31,12.1
	c-10.4,3.8-20.9,4.7-31.4,3.5c-7.4-0.9-13.4,0-19.9,3.9c-12.4,7.3-24.3,15.9-38.7,19.4c-4.2,1-6.5,3.3-6.9,7.6
	c-0.9,9.3-6.5,16.1-13.6,21.2c-3.2,2.3-8.1,2.3-12.3,3.2c-0.5,0.1-1.1-0.7-1.6-0.9c-1.9-0.7-3.9-2-5.7-1.8c-5,0.3-10.7,0-14.8,2.3
	c-5.7,3.2-8.1,0.8-10.1-2.9c-2.9,0.4-5.6,1.6-7.7,0.9c-11.7-4.1-22.9-1.8-34.1,1.9c-2.1,0.7-4.5,0.6-6.8,0.7
	c-3.3,0.2-7.5-1.2-9.6,0.5c-4.8,3.7-8.7,8.6-12.5,13.4c-3,3.8-6.4,5.1-11,3.9c-0.9-0.2-2.2-1-2.7-0.6c-4.3,3.2-9.1,6-12.3,10.1
	c-2.9,3.7-9.4,8-13.8,6.6c-0.4-0.1-0.5-0.9-0.3-0.5c-2.2,1.1-4.2,1.8-6,2.9C120.5,652.8,119.6,654,118.6,655z"
          />
        </svg>
        {/* Northern Territory */}
        <svg
          version="1.1"
          id="Layer_1"
          xmlns="http://www.w3.org/2000/svg"
          x="0px"
          y="0px"
          viewBox="0 0 249.9 448.3"
          style={{ enableBackground: "new 0 0 431.5 654" }}
          xmlSpace="preserve"
          className={`${
            checkedNT ? "fill-blue-600" : "fill-[#d3d3d3]"
          } absolute w-[4.5rem] top-[1rem] cursor-pointer left-[8.2rem] transition-all ease-in-out duration-300`}
          onClick={() => {
            setCheckedWA(false);
            setCheckedNT(true);
            setCheckedQLD(false);
            setCheckedSA(false);
            setCheckedNSW(false);
            setCheckedVIC(false);
            setCheckedTAS(false);
            setCheckedACT(false);
            setState(false);
          }}
        >
          <style type="text/css">
            .st0{}
            .st1{}
          </style>
          <path
            class="st0"
            d="M249.8,145.1c0.2,99.6,0.2,199,0.2,298.8c-1.3,1.9-1.4,4.3-3.8,5.2C166,449.3,86,449.3,5.5,449.3
	c-1.5-1.1-3.2-1.5-4-3.2c-0.3-0.6-0.4-1-0.6-1.7c-0.2-0.4-0.3-0.6-0.6-0.9C0,333,0,222.6,0,111.8c0.1-0.6,0.2-0.7,0.6-0.9
	c0.7,0.1,1.3,0.3,1.7-0.5c1.6-1.9,3.1-3.5,5.9-3.6c4.2-0.1,7.2-2.9,11.3-2.6c1.5,0.1,3.2-2.5,2.9-4.6c-0.2-2-0.7-4.1-3.2-5.2
	c-9.4-4-9.7-6.6-1.5-13.3c4-3.2,4.4-7.7,5.1-12c1-6.2,1.4-6.8,7.6-7.8c4-0.7,4.6-1.7,3.3-5.7c-1.7-5.1-1-7.5,3.2-10.8
	c1.4-1.1,2.3-2.4,3.1-3.8c0.9-1.6,2.2-2.6,4-2.5c5.7,0.1,9.9-1.9,12.6-7.3c2.5-5.1,7-6.2,12.6-5.7c8.4,0.7,16.8,0.7,25.2,1
	c2.8,0.1,3.7-2.9,6.5-2.7c2,0.4,3.2,2.1,5.2,1.9c0.2-1.4-1.2-2.2-1.3-3.9c-0.6-4,0.1-7.8-1.2-11.4c-1.1-3,2.2-6.6,5.7-6.5
	c2.9,0.1,5.8,1,7.4,3.5c3.4,5.2,8.8,5.8,14.6,6.7c2.1,0.9,3.7,2.1,5,3.5c1.5,1.5,2.8,1.2,4.4-0.4c2.3-0.7,3.8,0.6,5.5,1.2
	c4.4,1.6,8.8,3.1,13.6,2.3c2.3-0.4,4.3,0.1,5.9,1.9c1.6,1.8,3.8,2.6,6,3.5c2,0.9,3.6,1.4,4.8-1.4c0.9-2.2,3.2-3.2,5.4-2.9
	c6.2,0.9,12.6,1.5,15.9,8c0.9,1.7,2.2,2.5,4.1,1.8c1.9-0.7,2.5-2.3,1.6-4c-1.6-3.1-0.3-5.1,2.2-6.7c2.7-1.7,5.3-0.8,7.3,1.1
	c2.3,2.2,4.5,4,7.9,4.7c3.7,0.8,4.3,5.9,1.3,9c-4.8,4.9-9,10-9.3,17.5c-0.1,3.3-2.7,4.7-5.8,3.2c-4.3-2-7,0.5-9.7,3
	c-2.7,2.6-2.4,5.6-0.9,8.7c3.2,6.9,1.1,13-3.7,17.8c-4.1,4.1-5.1,10.1-9.8,13.7c-1.2,0.9-0.3,2.5,0.5,3.6c1.6,2.3,4,3.4,6.5,4.4
	c6.8,2.8,12.4,6.9,17.4,12.8c4,4.7,9.4,9.4,16.8,9.1c0.7,0,0-0.3,0.5,0C233.1,134.3,243.5,136.1,249.8,145.1z"
          />
          <path
            class="st1"
            d="M1.4,112c-0.1-0.1,0.1-0.5,0.3-0.5C2,111.7,1.8,111.9,1.4,112z"
          />
          <path
            class="st1"
            d="M1.9,444.2c-0.2,0-0.5-0.1-0.8-0.6C1.5,443.4,1.7,443.6,1.9,444.2z"
          />
        </svg>
        {/* Southern Australia */}
        <svg
          version="1.1"
          id="Layer_1"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 332 376"
          style={{ enableBackground: "new 0 0 431.5 654" }}
          xmlSpace="preserve"
          className={`${
            checkedSA ? "fill-blue-600" : "fill-[#d3d3d3]"
          } absolute w-[6rem] top-[9.2rem] cursor-pointer left-[8.2rem] transition-all ease-in-out duration-300`}
          onClick={() => {
            setCheckedWA(false);
            setCheckedNT(false);
            setCheckedQLD(false);
            setCheckedSA(true);
            setCheckedNSW(false);
            setCheckedVIC(false);
            setCheckedTAS(false);
            setCheckedACT(false);
            setState(false);
          }}
        >
          <style type="text/css">.st0{}</style>
          <path
            class="st0"
            d="M331.6,3.7c0.4,123,0.3,245.7,0.5,368.4c0,4.4-1.1,5.6-5.2,5c-2.3-0.3-4.7-0.1-7.5-0.1c-4.9-1.7-7.8-5-10.2-8.9
	c-2.7-4.4-5.8-8.6-9.1-12.5c-3.5-4.1-4.8-9-5.8-14c-0.4-1.9-0.2-3.8,1-5.2c2.7-3.2,2-6.5,0.8-9.9c-1.4-3.9-2.5-7.9-3.6-11.9
	c-2.8-10.4-11.7-15.3-19.9-20.6c-1.9-1.2-3.9-0.1-5.7,0.8c-2.9,1.6-5.9,2.3-9.1,1.6c-4.7-1-6.5-5.1-3.3-8.5c3.8-4,3.7-8.7,5-13.5
	c2.3-8.6-1.4-14.7-5.4-21.2c-0.4-0.6-0.6-1.2-1.6-1.8c-2.5,2.3-3.6,5.3-3.7,8.6c-0.2,5.5-2.4,10.3-4.5,15.2c-1.3,3.1-3.7,4.9-7,4.4
	s-5.8,0.6-8.3,2.4c-1.4,1-2.8,1.7-4.5,2.1c-5.2,1.2-8.5-1.9-7.7-7.2c1.3-8,3.9-10.2,11.9-9.7c2.8,0.2,3.4-1.2,3.9-3.3
	c1.2-4.8,0.1-9.6,0.2-14.4c0.1-7.8,2.7-14.3,8.3-19.6c2.9-2.7,4.7-5.4,2.7-9.5c-0.8-1.6-1-3.9,0-5.4c2.7-4.1,2.4-8.5,2.1-13
	c0.3,2.9-1.5,4.8-3.6,6.1c-3.5,2.1-5.4,5.2-6.6,9c-1,3-2.1,6-3.3,8.9c-1.4,3.4-3.7,6-7.4,6.9c-10.2,2.6-17,9.7-22.5,17.9
	c-3.3,4.9-7.3,9.5-9.4,15.2c-1.3,3.5-3.7,6-7.9,5.5c-3.6-0.4-7.5-4-8.2-7.8c-1.5-8.5-6.2-16.1-7.1-24.8c-0.1-1-0.5-2.2-1.3-2.7
	c-6.7-4.3-8.8-11.4-11.8-18c-0.9-2.1-2.4-2.8-4.6-3.2c-9.5-1.9-12.7-7.5-9.5-16.6c1-2.8-0.5-4.4-2.5-6.2c-0.7-0.8-0.9-1.4-0.9-2.4
	c0.1-0.7,0.6-1,0.5-1.3c-0.3-0.6,0.2-0.1-0.6,0.3c-1.5,0.2-2.5-0.2-3.9-1c-1.1-0.8-2-1.3-2.6-2.1c-3-3.8-6.2-4.9-10.7-2
	c-3,1.9-6.5,1-9.5-0.8c-2.3-1.4-4.4-3-6.4-4.8c-2.4-2.1-5-2.7-7.8-1.2c-5.3,2.8-10,1.5-14.3-2.1c-6.1-5.1-12.9-8.9-19.9-12.3
	c-2.7-1.3-5-1.3-7.5,0.2c-3.8,2.1-7.9,2.8-12.1,2c-8.4-1.5-16.6,0-24.9,0.8c-4.1,0.4-8.3,0.7-12.4,1.2c-2.3,0.3-4.7,0.1-6.6-2.4
	C0,110.6,0,57.3,0,3.5C1.7,1.6,3.8,2,5.8,2C112,2,218.3,2,324.6,2C326.9,2,329.3,1.7,331.6,3.7z"
          />
        </svg>
        {/* Queensland */}
        <svg
          version="1.1"
          id="Layer_1"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 435 534"
          style={{ enableBackground: "new 0 0 431.5 654" }}
          xmlSpace="preserve"
          className={`${
            checkedQLD ? "fill-blue-600" : "fill-[#d3d3d3]"
          } absolute w-[8rem] top-[0.5rem] cursor-pointer left-[12.9rem] transition-all ease-in-out duration-300`}
          onClick={() => {
            setCheckedWA(false);
            setCheckedNT(false);
            setCheckedQLD(true);
            setCheckedSA(false);
            setCheckedNSW(false);
            setCheckedVIC(false);
            setCheckedTAS(false);
            setCheckedACT(false);
            setState(false);
          }}
        >
          <style type="text/css">
            .st0{}
            .st1{}
          </style>
          <path
            class="st0"
            d="M380.2,534.7c-2.9,0.3-5.6,0.3-8.8,0.3c-4.1-7.9-10.1-11.6-19.1-12.5c-5.8-0.6-11.4-3.4-17.5-1.3
	c-0.9,0.3-2.1,0.2-3-0.1c-7.4-2.7-12.6,1.4-17.2,6c-4,3.9-8.4,5.5-13.9,5.5c-67.8-0.1-135.7,0-203.5-0.1c-9.8,0-11.8-1.9-11.8-11.7
	c-0.1-17.2,0-34.3,0-51.5c0-6.6-0.3-6.8-6.7-6.8c-22.7,0-45.3,0-68,0c-3.8,0-7.8,0.3-10.4-4.2C0,360,0,262,0,163.5
	c4-4.3,5.4-4.3,8.9-0.3c2,2.3,4.3,3.5,7.3,3.8c6.3,0.7,12.5,2,18.4,4.3c2.9,1.1,5.8,2.7,6.5,5.7c1.4,5.8,5.9,8.6,10.1,11.8
	c9.1,6.7,22.2,7,29.7,1c2.2-1.8,3.7-3.7,4-6.6c0.4-5,2.9-9,5.7-13.1c5-7.4,8.5-15.3,7.6-24.7c-0.5-5,1.5-9.9,3.4-14.5
	c2.7-6.2,3.3-12.2,1.6-18.9c-2.1-8.5,1.5-17.7-2.3-26.3c-0.7-1.5,0.6-3.6,1.2-5.3c2.1-5.2,4-10.2,2.8-16.3c-1-4.7,3.3-7.2,7.4-9.6
	c-2.8-2.1-4.9-4.5-5.5-8c-0.4-2.8,0.4-5.7,2.1-7.5c7.8-8.3,7.8-19.1,9.7-29.3c1.1-5.6,3.1-7.4,8.9-6.9c10.6,0.9,12.9,3.1,15.5,15
	c0.2,3.5,0,6.7,0.3,10.2c0.9,1.9,2.6,1.8,4,2.3c3.6,1.2,4.9,3.7,3,6.9c-1.7,2.9-1.1,4.7,1.1,7c5.9,6.2,8.3,14.1,9.7,22.3
	c0.9,5.2,2.4,10.4,1.2,15.8c-0.3,1.3,0.3,2.6,1,3.8c2.1,3.7,3.3,7.6,4.3,11.7c1.3,5.2,3.8,6,7.5,2.2c2.5-2.6,5.5-3.6,8.9-4
	c2.8-0.4,5.2,0.5,6.2,3.2c2.4,6.6,8.2,9.4,13.5,12.7c5.2,3.3,6.3,4.9,5.5,10.9c-1,8.3,1.1,16.1,3.9,23.8c1,2.9,2.1,5.5,1.4,8.6
	c-0.9,3.6,0.3,6.7,2.5,9.8c3.9,5.6,9,10.1,12,16.4c3.2,6.7,4.4,13.4,2.4,20.3c-1.8,6.2-1,11.5,3.1,16.3c2.3,2.8,3.7,5.8,3.4,9.6
	c-0.2,3,1.4,5.5,4.1,6.3c4.7,1.5,8.7,4.2,13,6.3c4.3,2.1,7.9,5,13.1,5.1c2.1,0,3.3,2.3,3.7,4.3c1,5.4,5.1,7.3,9.7,7.8
	c3.8,0.4,7.1,1.3,9.5,4.6c0.9,1.3,2.3,2,3.9,1.8c3.6-0.3,5.9,1.7,8.3,3.9c4.5,4,4.7,4.8,2,10.2c-2,3.9,0.5,6.3,2.9,8.6
	c1.4,1.4,3.4,2.1,5.2,3c2.9,1.4,4.8,3.5,4.9,6.9c0,1,0,2.1,0.6,2.9c6.7,8.7,6.7,19.5,9.5,29.4c0.4,1.5,1.1,2.9,2.7,3.3
	c2.1,0.5,2.3-1.3,3.1-2.5c2.8-4.2,5.6-4.4,9.3-0.8c4.3,4.3,8.5,8.7,15.2,9.6c4,0.6,4.9,4.7,4,8c-1.5,5.2,0,10.3,0,15.4
	c0,3.6,1,6.6,5.3,8c3.4,1.1,5.3,4.4,7.2,7.4c1.8,2.9,4.1,4.6,7.6,5c6.1,0.8,10.5,3.8,12.1,10.1c1.1,4.2,3.5,7.4,7.8,9.1
	c3,1.2,4.8,3.7,6,6.9c1.5,4.2,3.3,8.4,8.2,10c2.6,0.8,3.4,2.9,3.2,5.6c-0.5,5.9,0.1,11.2,5.6,15c2.3,1.5,2.2,4.4,1.5,6.9
	c-1.6,5-1.4,10.1-0.9,15.3c0.5,4.9-0.7,9.8-0.8,14.8c-0.1,3.9,0.2,7.2,3.4,9.8c0.9,0.7,1.7,1.7,2,2.8c1.9,6.9,4.4,13.6,4.2,20.9
	c-0.1,2.3-0.9,4-3.2,4.5c-7.4,1.6-14.8,4.5-22.4,0.5c-1.6-0.8-3.2-0.6-4.8,0.2c-1,0.6-1.9,1.4-3,1.8c-4.8,1.5-8,3.4-6.4,9.7
	c0.9,3.6-4.4,7.2-8.9,6.2C383.7,531.7,381.8,532,380.2,534.7z"
          />
          <path
            class="st1"
            d="M142,18.4c2,3.3,0.7,7.4,0.4,11.5C142,26.3,142,22.6,142,18.4z"
          />
        </svg>
        {/* New South Wales */}
        <svg
          version="1.1"
          id="Layer_1"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 351 300"
          style={{ enableBackground: "new 0 0 431.5 654" }}
          xmlSpace="preserve"
          className={`${
            checkedNSW ? "fill-blue-600" : "fill-[#d3d3d3]"
          } absolute w-[6.5rem] top-[10.3rem] cursor-pointer left-[14.5rem] transition-all ease-in-out duration-300`}
          onClick={() => {
            setCheckedWA(false);
            setCheckedNT(false);
            setCheckedQLD(false);
            setCheckedSA(false);
            setCheckedNSW(true);
            setCheckedVIC(false);
            setCheckedTAS(false);
            setCheckedACT(false);
            setState(false);
          }}
        >
          <style type="text/css">.st0{}</style>
          <path
            class="st0"
            d="M250,301c-2.7,0-5.4,0-8.2-0.3c-1.6-1.1-3.1-2.1-4.7-2.6c-2.6-0.8-5.4-1-7.9-2.1c-9.2-4.4-18.3-8.9-27.4-13.6
	c-1.5-0.8-3.8-2.9-3.6-4c1.2-7.6-5.1-12.4-6.6-19c-0.5-2.1-3.9-4.6-6.3-4.9c-2.3-0.3-4.9,2.3-7.5,3.1c-6.8,2.2-13.6,3.8-20.6-0.4
	c-1.2-0.8-4.6-2.4-6.6,1c-0.6,1-3.7,1.2-5.5,0.8c-5.6-1.2-11-3-16.6-4.1c-3-0.6-6.1-0.2-9.2-0.4c-1.3-0.1-3.2-1-3.7-0.4
	c-0.8,0.9-1.1,2.7-1.1,4.1c0.1,2.6-2.9,6.5-5.1,5.6c-5.7-2.2-11.8-4.1-14.5-10.6c-2.6-6.2-7.9-9.2-13.9-11.4c-3.2-1.2-6.7-3-8.7-5.6
	c-2.3-2.9-2.8-7.1-4.5-10.5c-1.1-2.1-2.6-4.7-4.5-5.6c-2.7-1.2-6.1-1-9.2-1.1c-1.5-0.1-3.4,0.2-4.6,1c-4.8,3.1-6.5,2.6-7.5-3
	c-0.8-4.2-2.6-7.6-6.6-9c-3.9-1.4-7.9-2.4-12-2.9c-2.6-0.3-5.5,1-8.2,0.7c-4.6-0.6-9.4-1.2-13.5-3.2c-1.6-0.8-1.8-5.3-1.8-8.2
	C0,137.8,0,81.1,0,24.5C0,16.8,0.8,16,8.4,16c68,0,135.9-0.1,203.9,0.1c6.5,0,10.7-1.8,15-6.8c3.1-3.5,7.1-8.7,14-5.6
	c2.6,1.2,6.2,0.1,9.4,0.2c3.6,0.2,7.2,0.3,10.7,0.8c3.3,0.5,6.6,2.1,9.9,2c5-0.2,8.3,2.2,10.6,5.9c2,3.1,3.2,6.8,5.1,11.1
	c1.7-2.2,2.9-4.2,4.5-5.7c1.4-1.3,4.2-3.3,5-2.7c5.6,3.6,7.9,0,10-3.8c-1.2-1.6-2.4-3-3.5-4.5c1.4-2.2,2.7-4.2,4-6.2
	c13.7,0,27.4,0,41.5,0.4c0.7,2.2,0.9,4,1.1,5.7c0.4,2.3,1.7,5,1.1,7c-1.6,5.1-4.2,9.9-6.3,14.9c-0.7,1.6-1.7,3.5-1.4,5.1
	c1.7,7.7-0.9,14.6-3.4,21.6c-2.4,6.4-6.2,12.7-4.5,19.6c1.7,7,0.1,13.2-2.2,19.5c-2.7,7.5-3.4,15.8-10.4,21.6
	c-2.1,1.8-4.7,6.3-1,10.1c0.4,0.4,0.3,1.6,0.1,2.3c-1.3,4.4-11.4,10.9-14.6,10.7c-3.7,4-6.1,8.3-9.7,10.2c-5,2.6-2.4,7.8-5.2,11.1
	c-1.2,1.4-0.9,4.3-3.7,5.3c-1.1,0.4-2.6,2.2-2.4,2.9c1.8,6.1-1.6,10.2-4.6,14.8c0.3,0.7,0.8,1.8,1.4,2.9c-0.4-0.1-0.8-0.2-1.2-0.3
	c-2.2,4.5-6.1,9-6.1,13.4c0.1,4.6-1.1,7.3-3.9,10.2c-0.6,0.7-0.7,2.2-0.6,3.2c0.5,4.6,1.9,9.4-4.7,11.2c-0.7,0.2-0.9,2.3-1.5,3.4
	c-1.6,3.5-2.8,7.4-5.1,10.4c-3.4,4.4-5.6,9-4.7,14.4c1.3,8.8-1.5,17-4.6,24.8c-1.5,3.8-1.7,6.5,0.4,9.8c0.7,1.1,1.1,2.7,0.9,4.1
	C251.4,294.7,250.7,297.8,250,301 M207.9,232.7c-2.1,5-1,9.5,2,13.6c1.7,2.3,3.7,2.8,5.1-0.5c1.3-3.1,2.6-6.1,4.1-9.1
	c1.8-3.8,0-7-4.2-6.6C212.7,230.3,210.6,231.4,207.9,232.7z"
          />
        </svg>
        {/* Victoria */}
        <svg
          version="1.1"
          id="Layer_1"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 246 152"
          style={{ enableBackground: "new 0 0 431.5 654" }}
          xmlSpace="preserve"
          className={`${
            checkedVIC ? "fill-blue-600" : "fill-[#d3d3d3]"
          } absolute w-[4.5rem] top-[14.3rem] cursor-pointer left-[14.5rem] transition-all ease-in-out duration-300`}
          onClick={() => {
            setCheckedWA(false);
            setCheckedNT(false);
            setCheckedQLD(false);
            setCheckedSA(false);
            setCheckedNSW(false);
            setCheckedVIC(true);
            setCheckedTAS(false);
            setCheckedACT(false);
            setState(false);
          }}
        >
          <style type="text/css">
            .st0{}
            .st1{}
          </style>
          <path
            class="st0"
            d="M0.2,118.3C0,81.6,0,45.3,0,8.5c0.2-0.6,0.3-0.8,0.6-1.2c0.3-0.7,0.4-1.2,0.5-2C2.2,1.7,5,1.5,7.4,2.1
	C14,3.6,20.5,1.8,27.1,2.4c9,0.8,15.5,4.8,18.9,13.2c1.3,3.2,2.2,3.3,5.2,1.8C60,13,71,18.3,73.2,27.8c1.4,6.1,4.3,10.2,10.6,11.9
	c7.1,2,12,7,15.8,13.1c1.4,2.3,3.1,4.3,5.5,5.6c4.4,2.4,5.7,2.1,8.2-2.3c1.8-3.1,4.4-4.1,7.9-4.2c7.8-0.3,15.1,2,22.5,3.9
	c3,0.8,5.8,1,8.6-0.7c2.5-1.5,5.1-1.3,7.7-0.2c6,2.5,12,2.4,18,0.1c2.5-0.9,5.1-1.6,7.7-2.1c5.7-1.1,10.7,1.8,11.6,7.5
	c1.1,6.7,5.8,12,6.8,18.7c0.3,1.7,2.1,2.6,3.6,3.2c6.7,2.6,12.7,6.6,19.1,9.8c3.2,1.5,6.3,2.9,9.9,3.4c3.9,0.5,6.8,2.5,9,6.3
	c0.3,1.7,0.3,3.1,0.3,4.9c-3.3,5.3-7.9,7.8-13.8,8.3c-10.9,0.8-21.8,2.5-32.8,2.4c-5.9-0.1-11,2.1-14.6,7.9
	c-3.8,6.2-9.6,11.1-14.7,16.3c-3.6,3.6-8.8,4.5-13.8,5.2c-2.7,0.4-5,1-7.4,2.7c-5.4,3.8-9.9,3.1-14.1-2c-1.1-1.3-2.4-1.6-3.8-2.1
	c-6.9-2.4-8.8-5.6-7.4-13c-2,2.5-4.4,4.3-7.3,5.3c-1.8,0.6-3.6,0.9-5.2-0.5c-1.7-1.6-1.7-3.5-1.2-5.6c0.4-1.6,1.4-3,2.3-4.4
	c1.7-2.5,1.3-4.9-0.7-6.8c-1.9-1.8-3.2,0.5-4.7,1.3c-0.9,0.5-1.7,1.1-2.7,1.4c4.2,3.9,3.6,6.9-1.7,8.4c-7.7,2.1-13.7,6.7-18.7,12.6
	c-6.7,7.8-9.3,8.3-18.5,3.8c-4.2-2.1-8.2-4.5-12.3-6.7c-5.6-3-10.8-6.7-17.7-5.5c-1.4,0.2-3-0.5-4.3-1.1c-3.2-1.6-6.1-2-9.3,0.2
	c-2.8,1.8-6,1.3-8.2-1.1c-3.2-3.4-6.6-6.6-10.2-9.5C1.5,122.3,1.2,120.4,0.2,118.3z"
          />
          <path
            class="st1"
            d="M1.3,7.9C1.2,7.7,1.3,7.4,1.7,7C1.9,7.2,1.7,7.5,1.3,7.9z"
          />
        </svg>
        {/* Tasmania */}
        <svg
          version="1.1"
          id="Layer_1"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 107.3 114.1"
          style={{ enableBackground: "new 0 0 431.5 654" }}
          xmlSpace="preserve"
          className={`${
            checkedTAS ? "fill-blue-600" : "fill-[#d3d3d3]"
          } absolute w-[2rem] top-[18.8rem] cursor-pointer left-[15.1rem] transition-all ease-in-out duration-300`}
          onClick={() => {
            setCheckedWA(false);
            setCheckedNT(false);
            setCheckedQLD(false);
            setCheckedSA(false);
            setCheckedNSW(false);
            setCheckedVIC(false);
            setCheckedTAS(true);
            setCheckedACT(false);
            setState(false);
          }}
        >
          <style type="text/css">.st0{}</style>
          <path
            class="st0"
            d="M107,23.8c0.2,0.7,0.2,1.1,0.2,1.9c-1.5,2.5-1.6,5-1.4,7.5c0.6,7.6,1.4,14.9-4.3,21.7
	c-4.5,5.4-3.5,12.9-4.6,19.6c-1.3,8-3.1,10.2-11.1,10.9c-4.2,0.4-6.4,3.4-7.5,7c-0.9,3-2.2,5.3-4.8,7.2c-2.9,2.1-3,5.7-4,8.9
	c-1.8,6.2-7.5,7.7-12.2,2.9c-0.6-1.6,0.8-3.4-1.1-4.1c-1.6-0.5-1.7,1.6-3.1,2.3c-3.1,0.8-5.9,0.6-8.7,0.9c-4.5,0.5-6.9-1.4-7.6-5.9
	c-0.4-2.6-1.6-4.2-3.4-6.1c-3.7-4-8.3-7.5-10.1-13.5c1.8-5.4,1.4-6.6-3.2-9.2c-4.6-3.6-5.8-8.5-4.4-13.2c1.8-6.1-0.8-10.4-3.4-15.3
	c-4.7-8.9-10-17.5-12-27.6C-0.7,14.2,0.7,9.4,2.2,4.5c0.9-2.9,3.4-4,6.4-3.2C20,4.4,32.4,4.3,41.9,13c3.9,3.6,8.9,3.2,13.7,2.3
	c2.2-0.4,4.2-1,6.6,0.4C63.5,16.7,64,18,65,19c-0.7-2.1-0.8-4.2,0.4-6.5c2-2.3,4.5-2.2,6.7-2.1c7,0.3,12.4-4.3,18.6-6.1
	c7.4-2.2,9.8-1.8,14.1,4.7c0.9,1.4,1.3,3.1,2.3,4.8c0.2,0.7,0.2,1.2,0.2,1.9C105.5,18.4,105.8,20.9,107,23.8z"
          />
        </svg>
        {/* Canberra */}
        <svg
          version="1.1"
          id="Layer_1"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 368.4 506.2"
          style={{ enableBackground: "new 0 0 431.5 654" }}
          xmlSpace="preserve"
          className={`${
            checkedACT ? "fill-blue-600" : "fill-[#d3d3d3]"
          } absolute w-[2rem] top-[14rem] left-[19.8rem] cursor-pointer transition-all ease-in-out duration-300`}
          onClick={() => {
            setCheckedWA(false);
            setCheckedNT(false);
            setCheckedQLD(false);
            setCheckedSA(false);
            setCheckedNSW(false);
            setCheckedVIC(false);
            setCheckedTAS(false);
            setCheckedACT(true);
            setState(false);
          }}
        >
          <style type="text/css">.st0{}</style>
          <path
            class="st0"
            d="M83,471.9c-4.6-6.3-6.4-12.4-1.2-18.9c1.4-1.7,1.3-2.9-1.4-3.9c-7.6-3.2-9.5-8.1-7.1-15.8
	c3.3-10.6,3.5-21.3,1.8-32.3c-0.8-5.4-2.4-10.6-3.3-16c-0.4-2.2-1-4.5-4.1-4.8c-3-0.3-3.8,1.2-4.8,3.7c-1.8,5-4.2,9.8-6.5,14.5
	c-2.4,5.1-3.6,5.4-7.4,1c-7.5-8.9-15.8-17.1-24-25.3c-0.7-0.7-1.4-1.5-2.1-2.1c-5.2-4.3-9.7-8.4-4-16c2-2.6,1.1-6.6-2-9.5
	c-4.5-4.2-8.8-8.6-13.1-13.1c-3.8-4-3.2-6.7,2.1-8.6c3.5-1.3,5-3.1,2.6-6.7c-1.1-1.7-1.8-3.5-2.7-5.3c-2.8-5.6-2.4-6.9,2.6-10.7
	c4.8-3.6,5.2-5.7,2-10.8c-3.1-4.9-3.3-10.7-4.6-16.1c-2.6-11-2.3-22.5-5.5-33.5c-1.1-3.7,1.1-7.1,2.5-10.5c1-2.2,1.8-4.1,0.6-6.6
	c-1.1-2.3-0.8-5-0.3-7.4c2.8-12.3,7.1-24.1,12-35.6c2.7-6.3,5.3-12.5,8-18.8c0.8-1.9,1-4.1,0.5-5.9c-2.7-9.3-0.8-18.9-1.2-28.3
	c-0.2-3.8,2.5-6.4,5.2-8.7c14.6-12.5,30.1-23.8,46.4-34c12.5-7.8,25.2-15.2,37.9-22.8c12.6-7.6,25.3-15.2,38-22.7
	c14.4-8.5,27.8-18.5,41.5-28.2c3.6-2.6,7-5.5,10.3-8.5c4.5-4.2,8.4-5,13.9-2.2c14.7,7.5,25.4,19,33.2,33.3c0.6,1.2,1.8,2.5,1.1,3.7
	c-2.7,5.1-0.4,8.7,2.7,12.5c2.1,2.7,4.4,5.5,4.5,9.3c0.1,2.1,1.9,2,3.4,2c4,0,8,0,12,0c5.3,0,7,2.1,4.4,6.6
	c-2.8,4.8-0.8,6.4,3.2,8.6c6.2,3.3,13.4,5.5,16,13.2c0.7,2,2.8,2.2,4.7,2c17.1-1.7,29.4,7.8,41,18.1c4.6,4.1,8.4,6.4,14.9,4.3
	c7.6-2.4,12.5,2.5,11.8,10.6c-0.1,1.4-1.1,1.9-2.1,2.5c-4.1,2.2-8.1,4.6-12.2,6.7c-1.7,0.9-3.2,1.9-4.1,3.5c-1,1.9-0.7,4.8-3.9,5
	c-3.5,0.2-7.6,0.8-9.9-2.1c-5-6.1-12.5-6.2-18.9-7.2c-10.7-1.7-21.5-3.3-32.4-3.3c-2.7,0-4.9,0.6-7.4,2.1c-4.9,3-10,6-16.1,6.3
	c-1.7,0.1-2.6,1-3.5,2.3c-9.3,12.7-18.5,25.4-28,37.9c-2.9,3.8-6.1,7.4-9.4,10.9c-2.4,2.6-2.8,4.4,1,5.8c4.4,1.7,5.2,3.9,1.5,6.6
	c-8.4,6.2-10.1,13-3.8,21.6c1,1.4,1,3.6,2,5.1c5.2,7.5,3.5,15,0.2,22.5c-3.1,7-5.2,14.3-7,21.7c-1.6,6.5,1.2,12.8,2.9,18.9
	c1.4,5.1-1.4,4.7-4.2,4c-3.8-0.9-7.7-1.9-11.5-3c-4.5-1.2-8.9-0.8-13.3-0.9c-3.6-0.1-5.3,1.9-3.8,5.5c5.2,12.4,6.9,25.7,10.2,38.6
	c0.8,3.4,0.8,7,0.8,10.3c0,11.3,3.4,22.4,1.2,34c-1.5,7.9-0.9,16.4-4.7,23.6c-3.1,6-1.2,11.2,0.3,16.6c2.9,10,0.3,19.4-3,28.6
	c-5.6,15.6-15.2,29.4-19.9,45.5c-0.2,0.6-0.6,1.2-0.7,1.8c-2.4,9.3-9,11.4-15.6,4.2c-4.9-5.4-11.5-5.1-17.5-6.9
	c-13.1-3.9-26.6-7-38.8-13.4C91.8,479.1,87.1,476.3,83,471.9z"
          />
        </svg>
      </div>
      {state && (
        <main className="flex flex-col items-center">
          <p className="p-4 my-5 bg-blue-600 text-white md:w-[70%] sm:w-[90%] w-[98%] mx-auto text-center rounded-xl">
            Select a state from the above map to start exploring
          </p>
          <section className="flex mx-auto my items-center justify-center md:w-[480px] w-[420px] border-2 border-gray-600 shadow-lg rounded-lg p-4">
            <div className="flex flex-col items-center justify-center gap-2 py-2">
              <h2 className="mb-2 text-center font-semibold text-2xl">
                Australia
              </h2>
              <p className="w-[400px] text-center rounded-lg py-2 bg-gray-200 shadow-lg">
                Capital city: Canberra
              </p>
              <p className="w-[400px] text-center rounded-lg py-2 bg-gray-200 shadow-lg">
                Population: 25,688,079
              </p>
              <p className="w-[400px] text-center rounded-lg py-2 bg-gray-200 shadow-lg">
                Land area: 7,692 024 km2
              </p>
              <p className="w-[400px] text-center rounded-lg py-2 bg-gray-200 shadow-lg">
                People born overseas: 29.8%
              </p>
              <p className="w-[400px] text-center rounded-lg py-2 bg-gray-200 shadow-lg">
                People speaking non-English language at home: 23%
              </p>
            </div>
          </section>
          <img
            src="/majorCities_AUS.png"
            className="w-[480px] mt-5"
            alt="Major Cities"
          />
          <a
            className="text-center rounded-lg py-2 px-4 mt-5 bg-gray-200 shadow-lg"
            href="https://en.wikipedia.org/wiki/Australia"
          >
            Explore more
          </a>
        </main>
      )}
      {checkedNSW && (
        <main className="flex flex-col items-center">
          <div className="flex mx-auto my items-center justify-center md:w-[480px] w-[420px] border-2 border-gray-600 shadow-lg rounded-lg p-4">
            <div>
              <h2 className="mb-2 text-center font-semibold text-2xl">
                New South Wales
              </h2>

              <div className="flex flex-col items-center justify-center gap-2 py-2">
                <p className="w-[400px] text-center rounded-lg py-2 bg-gray-200 shadow-lg">
                  Population: 8,172,500
                </p>
                <p className="w-[400px] text-center rounded-lg py-2 bg-gray-200 shadow-lg">
                  Land area: 801,150 sq. km
                </p>
                <p className="w-[400px] text-center rounded-lg py-2 bg-gray-200 shadow-lg">
                  People born overseas: 29.3%
                </p>

                <p className="w-[400px] text-center rounded-lg py-2 bg-gray-200 shadow-lg">
                  People speaking non-English language at home: 26.6%
                </p>
              </div>
            </div>
          </div>
          <img
            className="w-[480px] mt-5"
            src="/majorCities_NSW.png"
            alt="NSW Cities"
          />
          <a
            className="text-center rounded-lg mt-5 py-2 px-4 bg-gray-200 shadow-lg"
            href="https://en.wikipedia.org/wiki/New_South_Wales"
          >
            Explore More
          </a>
        </main>
      )}

      {checkedVIC && (
        <main className="flex flex-col items-center">
          <div className="flex mx-auto my items-center justify-center md:w-[480px] w-[420px] border-2 border-gray-600 shadow-lg rounded-lg p-4">
            <div>
              <h1 className="mb-2 text-center font-semibold text-2xl">
                Victoria
              </h1>
              <div className="flex flex-col items-center justify-center gap-2 py-2">
                <p className="w-[400px] text-center rounded-lg py-2 bg-gray-200 shadow-lg">
                  Population: 6,548,040
                </p>

                <p className="w-[400px] text-center rounded-lg py-2 bg-gray-200 shadow-lg">
                  Land area: 227,444 sq. km
                </p>

                <p className="w-[400px] text-center rounded-lg py-2 bg-gray-200 shadow-lg">
                  People born overseas: 29.9%
                </p>

                <p className="w-[400px] text-center rounded-lg py-2 bg-gray-200 shadow-lg">
                  People speaking non-English language at home: 27.6%
                </p>
              </div>
            </div>
          </div>
          <img
            className="w-[480px] mt-5"
            src="/majorCities_VIC.png"
            alt="VIC Cities"
          />
          <a
            className="text-center rounded-lg py-2 px-4 mt-5 bg-gray-200 shadow-lg"
            href="https://en.wikipedia.org/wiki/Victoria_(Australia)"
          >
            Explore More
          </a>
        </main>
      )}

      {checkedQLD && (
        <main className="flex flex-col items-center">
          <div className="flex mx-auto my items-center justify-center md:w-[480px] w-[420px] border-2 border-gray-600 shadow-lg rounded-lg p-4">
            <div>
              <h1 className="mb-2 text-center font-semibold text-2xl">
                Queensland
              </h1>
              <div className="flex flex-col items-center justify-center gap-2 py-2">
                <p className="w-[400px] text-center rounded-lg py-2 bg-gray-200 shadow-lg">
                  Population: 5,217,653
                </p>

                <p className="w-[400px] text-center rounded-lg py-2 bg-gray-200 shadow-lg">
                  Land area: 1,727,000 sq. km
                </p>

                <p className="w-[400px] text-center rounded-lg py-2 bg-gray-200 shadow-lg">
                  People born overseas: 22.7%
                </p>

                <p className="w-[400px] text-center rounded-lg py-2 bg-gray-200 shadow-lg">
                  People speaking non-English language at home: 13.2%
                </p>
              </div>
            </div>
          </div>
          <img
            className="w-[480px] mt-5"
            src="/majorCities_QLD.png"
            alt="QLD Cities"
          />
          <a
            className="text-center rounded-lg py-2 px-4 mt-5 bg-gray-200 shadow-lg"
            href="https://en.wikipedia.org/wiki/Queensland"
          >
            Explore More
          </a>
        </main>
      )}

      {checkedSA && (
        <main className="flex flex-col items-center">
          <div className="flex mx-auto my items-center justify-center md:w-[480px] w-[420px] border-2 border-gray-600 shadow-lg rounded-lg p-4">
            <div>
              <h1 className="mb-2 text-center font-semibold text-2xl">
                South Australia
              </h1>
              <div className="flex flex-col items-center justify-center gap-2 py-2">
                <p className="w-[400px] text-center rounded-lg py-2 bg-gray-200 shadow-lg">
                  Population: 1,803,192
                </p>

                <p className="w-[400px] text-center rounded-lg py-2 bg-gray-200 shadow-lg">
                  Land area: 983,482 sq. km
                </p>

                <p className="w-[400px] text-center rounded-lg py-2 bg-gray-200 shadow-lg">
                  People born overseas: 24%
                </p>

                <p className="w-[400px] text-center rounded-lg py-2 bg-gray-200 shadow-lg">
                  People speaking non-English language at home: 17.8%
                </p>
              </div>
            </div>
          </div>
          <img
            className="w-[480px] mt-5"
            src="/majorCities_SA.png"
            alt="SA Cities"
          />
          <a
            className="text-center rounded-lg py-2 px-4 mt-5 bg-gray-200 shadow-lg"
            href="https://en.wikipedia.org/wiki/South_Australia"
          >
            Explore More
          </a>
        </main>
      )}

      {checkedWA && (
        <main className="flex flex-col items-center">
          <div className="flex items-center justify-center mx-auto my-4 md:w-[480px] w-[420px] border-2 border-gray-600 shadow-lg rounded-lg p-4">
            <div>
              <h1 className="mb-2 text-center font-semibold text-2xl">
                Western Australia
              </h1>
              <div className="flex flex-col items-center justify-center gap-2 py-2">
                <p className="w-[400px] text-center rounded-lg py-2 bg-gray-200 shadow-lg">
                  Population: 2,749,864
                </p>

                <p className="w-[400px] text-center rounded-lg py-2 bg-gray-200 shadow-lg">
                  Land area: 2,527,013 sq. km
                </p>

                <p className="w-[400px] text-center rounded-lg py-2 bg-gray-200 shadow-lg">
                  People born overseas: 32.2%
                </p>

                <p className="w-[400px] text-center rounded-lg py-2 bg-gray-200 shadow-lg">
                  People speaking non-English language at home: 18.4%
                </p>
              </div>
            </div>
          </div>
          <img
            className="w-[480px] mt-5"
            src="/majorCities_WA.png"
            alt="WA Cities"
          />
          <a
            className="text-center rounded-lg py-2 px-4 mt-5 bg-gray-200 shadow-lg"
            href="https://en.wikipedia.org/wiki/Western_Australia"
          >
            Explore More
          </a>
        </main>
      )}

      {checkedACT && (
        <main className="flex flex-col items-center">
          <div className="flex mx-auto my items-center justify-center md:w-[480px] w-[420px] border-2 border-gray-600 shadow-lg rounded-lg p-4">
            <div>
              <h1 className="mb-2 text-center font-semibold text-2xl">
                Australian Capital Territory
              </h1>
              <div className="flex flex-col items-center justify-center gap-2 py-2">
                <p className="w-[400px] text-center rounded-lg py-2 bg-gray-200 shadow-lg">
                  City: Canberra
                </p>

                <p className="w-[400px] text-center rounded-lg py-2 bg-gray-200 shadow-lg">
                  Population: 453,558
                </p>
                <p className="w-[400px] text-center rounded-lg py-2 bg-gray-200 shadow-lg">
                  Land area: 2,358 sq. km
                </p>
                <p className="w-[400px] text-center rounded-lg py-2 bg-gray-200 shadow-lg">
                  People born overseas: 28.7%
                </p>
                <p className="w-[400px] text-center rounded-lg py-2 bg-gray-200 shadow-lg">
                  People speaking non-English language at home: 24.6%
                </p>
              </div>
            </div>
          </div>

          <a
            className="text-center rounded-lg py-2 px-4 mt-10 bg-gray-200 shadow-lg"
            href="https://en.wikipedia.org/wiki/Canberra"
          >
            Expore More
          </a>
        </main>
      )}

      {checkedTAS && (
        <main className="flex flex-col items-center">
          <div className="flex mx-auto my items-center justify-center md:w-[480px] w-[420px] border-2 border-gray-600 shadow-lg rounded-lg p-4">
            <div className="flex flex-col items-center justify-center">
              <h className="mb-2 text-center font-semibold text-2xl">
                Tasmania
              </h>

              <p className="w-[400px] text-center rounded-lg py-2 bg-gray-200 shadow-lg">
                Population: 567,909
              </p>
              <p className="w-[400px] text-center rounded-lg py-2 bg-gray-200 shadow-lg">
                Land area: 68,401 sq. km
              </p>
              <p className="w-[400px] text-center rounded-lg py-2 bg-gray-200 shadow-lg">
                People born overseas: 15.3%
              </p>
              <p className="w-[400px] text-center rounded-lg py-2 bg-gray-200 shadow-lg">
                People speaking non-English language at home: 8.7%
              </p>
            </div>
          </div>
          <img
            className="w-[480px] mt-5"
            src="/majorCities_TAS.png"
            alt="TAS Cities"
          />
          <a
            className="text-center rounded-lg py-2 px-4 mt-5 bg-gray-200 shadow-lg"
            href="https://en.wikipedia.org/wiki/Tasmania"
          >
            Explore More
          </a>
        </main>
      )}
      {checkedNT && (
        <main className="flex flex-col items-center">
          <div className="flex mx-auto my items-center justify-center md:w-[480px] w-[420px] border-2 border-gray-600 shadow-lg rounded-lg p-4">
            <div className="flex items-center justify-center flex-col gap-2">
              <h1 className="mb-2 text-center font-semibold text-2xl">
                Northern Territory
              </h1>

              <p className="w-[400px] text-center rounded-lg py-2 bg-gray-200 shadow-lg">
                Population: 249,200
              </p>
              <p className="w-[400px] text-center rounded-lg py-2 bg-gray-200 shadow-lg">
                Land area: 1,419,630 sq. km
              </p>
              <p className="w-[400px] text-center rounded-lg py-2 bg-gray-200 shadow-lg">
                People born overseas: 21.8%
              </p>
              <p className="w-[400px] text-center rounded-lg py-2 bg-gray-200 shadow-lg">
                People speaking non-English language at home: 32.4%
              </p>
            </div>
          </div>
          <img
            className="w-[480px] mt-5"
            src="/majorCities_NT.png"
            alt="Northern Territory Cities"
          />
          <a
            className="text-center rounded-lg py-2 px-4 mt-5 bg-gray-200 shadow-lg"
            href="https://en.wikipedia.org/wiki/Northern_Territory"
          >
            Explore More
          </a>
        </main>
      )}
    </main>
  );
};

export default UniversityFinder;
